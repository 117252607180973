<script setup lang="ts">
const appName = getAppName()

useHead({
  titleTemplate(title) {
    return title ? `${title} - ${appName}` : appName
  },
})
</script>

<template>
  <NuxtErrorBoundary @error="showError($event as any)">
    <div class="flex h-full flex-col">
      <div>
        <MobileNavbar class="lg:hidden" />
        <GlobalBanner />
        <GlobalModals />
        <NavBar />
        <Container>
          <slot />
        </Container>
        <Background fixed />
      </div>

      <CopyrightFooter variant="dark" class="mt-auto" />
    </div>
  </NuxtErrorBoundary>
</template>
